import React, { useEffect } from 'react';




export const AnswersAll  = () => {
    let prueba = new Map();
    let questions = "";
    
    useEffect(function () {
         getResults();
        
    })


    const getResults = async() =>{
        
        

        await fetch("https://sheetlabs.com/ADST/Quiz_Scores" , {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            
        },
        })
        .then(response => {
        if (response.status === 200) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
        })
        .then(data => {
            
            for(let l = 0 ; l < data.length ; l++){
                questions = data[l].question.split(';')
                var answers = data[l].answers.split(';')
                var user =  data[l].user.split(';')[0];
                

                prueba.set(user, answers)
                
                
            }
        })
        .catch((error) => {
            console.error(error);
        });
        
      

        putHtml();
        
    }

    
    

    const putHtml = () => {
        let bodyText= "";
        
        for(let l = 0 ; l< questions.length ; l++){
            bodyText= bodyText+`<h3><strong>${l+1}.</strong>&nbsp${questions[l]}</h3>`
            for (var [key, value] of prueba) {
                bodyText= bodyText + `<p>${key} → ${value[l]}</p>`
              }
        }

        document.getElementById('data').innerHTML = bodyText;
    }

    return ( 
        <div>
            <h1>Todas las respuestas</h1>
            <div id="data"></div>
        </div>

    )
}

    