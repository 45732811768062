import React, { useState, useEffect } from 'react';
import { AnswersForUser } from './AnswersForUser';

export  const Users = () => {
    let users = new Map();
    const [user,setUser] = useState("");
    let questions = [] ;
    let answer=[];
    const [body,setBody]=useState([]);
    let [bandera,setBandera]= useState( true);
    const [button, setButton] = useState("");
    let[id, setId] = useState(0);
    let [show, setShow] = useState(false);
    let [body2, setBody2] = useState();
   
    useEffect( () =>{
       
         getResults();
         
    })

    

    const getResults = async() =>{
        
        
        if(bandera){
            setBandera( false);
          
         await fetch("https://sheetlabs.com/ADST/Quiz_Scores" , {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            
         },
         })
         .then(response => {
         if (response.status === 200) {
             return response.json();
         } else {
             throw new Error('Something went wrong');
         }
         })
         .then(data => {
            
             for(let l = 0 ; l < data.length ; l++){
                 questions = data[l].question.split(';')
                 var answers = data[l].answers
                 var user =  data[l].user.split(';')[0] + ";" + data[l].__id;
                    console.log(user);

                 users.set(user, answers)
                
                
             }
         })
         .catch((error) => {
             console.error(error);
         });
        
        
      
        putHtml();
        
        }
    }

    const handleButtons = event => {
        setButton("button_usersInv");
        setUser(event.target.textContent);
   
        answer=event.target.value.split(';');
        
        
        setShow(true);
        setId(event.target.id)
        putHtml2();
    }
    
    const putHtml2=() =>{
        let text = [];
            
        for(let l = 0; l <questions.length ; l++){
            text.push ( <h3><strong>{l+1}.</strong> {questions[l]}</h3>);
            text.push (<p>{answer[l]}</p>);
            
        }
        setBody2(text);
        
        
         
       
    }

    const putHtml = () => {
        
        let text = [];
        
        let id = 0;
        for (var [key, value] of users) {
            id=key.split(';')[1];
            
            text.push(<button id={id} className="button_users" value={value} onClick={handleButtons}>{key.split(';')[0]}</button> );
            
            
           
           
        }
        setBody(text);
        
    }
    
    return ( 
        <div>
            <h1>Respuestas por usuario</h1>
            <div className={button}>{body}</div>
            
            <p><AnswersForUser id={id} show={show} body={body2} user={user} /></p>
        </div>

    )
}

    