import React from 'react';


export  const AnswersForUser = (props) => {
   

    const deleteAnswer = ()=>{
        let data = [ {
            "__id" : props.id
          } ]
           fetch("https://sheetlabs.com/ADST/Quiz_Scores" , {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              
          },
            body: JSON.stringify(data),
          })
          .then(response => {
            if (response.status === 204) {
              console.log('Success!');
            } else {
              throw new Error('Something went wrong on api server!');
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }

   
   if(props.show){
    return ( 
        <div>
            <h1 className='h1_nombre'>{props.user}</h1>
            <div>{props.body}</div>
            <button on onClick={deleteAnswer}>Borrar</button>
        </div>
    )
   }else{
    return 
        
    
   }
    
}

    