import React from 'react'
import { AnswersAll } from './AnswersAll';
import { Users } from './Users';




export function GetAnswers(props)  {
    const option = props.option;
    switch (option) {
        case 0:
            return <AnswersAll/>
           
    
        case 1:
            return <Users/>
            
        default:

            break;
        

    } 
}