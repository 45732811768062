import '../styles/Home.css'
import statisticsImg from "../assets/statistics.png"



function Welcome() {


  

  return (
    <div>
      

      <header>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Hachi+Maru+Pop&family=Poppins:ital,wght@0,200;1,800&display=swap" rel="stylesheet"/>
      </header>
      <body>
        <div >
          <a href='/statistics'><img className='img_statistics' alt='button_statistics' src={statisticsImg}></img></a>
        </div>
        <div className="container">
          <div className="box">
            <h1 className='text_welcome'>Welcome</h1>
            <h2 className='text_subwelcom'>Al Quiz</h2>
            
            <a className="a_button" href='/quiz'><button type="button"  >¡Vamos Alla!</button>  </a>
            <img className= "logo" src="https://www.adstore.es/wp-content/uploads/2021/09/logo-ad-store.png" alt='logo'/>
          </div>
          <img className= "img_pig" src="https://www.adstore.es/wp-content/uploads/2023/04/CERDO.RELLENO.png" alt='Cerdo'></img>
          <img className= "img_ovni" src="https://www.adstore.es/wp-content/uploads/2023/04/PERRO_OVNI.RELLENO.png" alt='Ovni_perro'></img>
          <img className= "img_wings" src="https://www.adstore.es/wp-content/uploads/2023/04/ALAS.RELLENO.png" alt='Alas'></img>
          <img className= "img_people " src="https://www.adstore.es/wp-content/uploads/2023/04/ABRAZADOS.RELLENO-1.png" alt='Personas'></img>
          <img className= "img_skate" src="https://www.adstore.es/wp-content/uploads/2023/04/SKATE.RELLENO.png" alt='Skate'></img>
          <img className= "img_people2" src="https://www.adstore.es/wp-content/uploads/2023/04/NOSOTROS.png" alt='Personas2'></img>

        </div>
        
      </body>
    </div>
    
  );
}




export default Welcome;