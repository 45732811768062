import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Quiz from './pages/Quiz';
import Home from './pages/Home';
import Complete from './pages/Complete';
import Statistics from './pages/Statistics';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path: "/quiz",
    element: <Quiz/>
  },
  {
    path: "/complete",
    element: <Complete/>
  },
  {
    path: "/statistics",
    element: <Statistics/>
  },
  
  
])





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RouterProvider router={router}/>
  /* </React.StrictMode> */
);

reportWebVitals();

