import '../styles/Quiz.css';
import '../styles/Loging.css'

import userImg from '../assets/leon.png'
import { useEffect, useState } from 'react';
import { Preguntas } from '../components/Pregustas';



function Quiz() {
  let validate = false;
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [textError, setTextError] = useState("");
  const [invisibleQuiz, setInvisibleQuiz] = useState("boxQuizInv");
  const [invisibleLogger, setInvisibleLogger] = useState("boxLogger");
  //const [type, setType] = useState("password")
  

  let checkUsers = async ()  => {
    console.log("hola")
    await fetch("https://sheetlabs.com/ADST/Quiz_Users" , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',

    },
  })
  .then( response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Something went wrong');
    }
  })
  .then(data => {
    
    for (let i = 0; i < data.length; i++) {
      
      let name = data[i].name;
      let password = data[i].password;
      
      if(name === userName.toLocaleLowerCase() && password === userPassword){
        validate = true;
        
      }
        
    }
    if(validate){
      setTextError("");
      // window.location.href ='/quiz';
      setInvisibleQuiz('boxQuiz')
      setInvisibleLogger('boxLoggerInv')
    }else{
      setTextError("Nombre o Contraseña incorrecta");
      setInvisibleQuiz('boxQuizInv')
      setInvisibleLogger('boxLogger')
    }
    
  })}
  useEffect(()=>{
    document.addEventListener('keydown', detectKey, true)
  })
  
  const detectKey = (e) => {
    if (e.keyCode === 13) {
      checkUsers()
    }
}

  const handleUser = event =>{
     setUserName(event.target.value);    
    
  }

  const  handlePassword = event =>{
    setUserPassword(event.target.value);
  }



  
  
  return (
    <div>
      <header>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Hachi+Maru+Pop&family=Poppins:ital,wght@0,200;0,400;0,700;1,800&display=swap" rel="stylesheet"></link>
      </header>
      <body>
        <div className={invisibleQuiz}>
          <img class= "logo" src="https://www.adstore.es/wp-content/uploads/2021/09/logo-ad-store.png" alt='logo'/>
          <Preguntas userName={userName.toLocaleUpperCase()}/>
        </div>
        
        <div className={invisibleLogger}>
          <img className="userImg" src={userImg} alt='logo'/>
          <p className= "textError" >{textError}</p>

          <input className="textField textField2" id= "nombre" type="text"   placeholder="Nombre de Usuario" onChange={handleUser} />
          <input className="textField textField2" type="password" placeholder="Contraseña" onChange={handlePassword} />
          

          <button className= "button2" type="submit" onClick={()=>{checkUsers()}} >Iniciar sesion</button>
          
        </div>
      </body>

    </div>
  )
  
}



export default Quiz;