
import '../styles/Statistics.css';
import userAdminImg from '../assets/leonAdmin2.png';
import alien from '../assets/alien.png';
import ray1 from '../assets/ray1.png';
import ray2 from '../assets/ray2.png';
import { useState } from 'react';
import {GetAnswers} from '../components/GetAnswers';


function Statistics()  {  
  let validate = false;
  
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [textError, setTextError] = useState("");
  const [invisibleQuiz, setInvisibleQuiz] = useState("boxQuizInv");
  const [invisibleLogger, setInvisibleLogger] = useState("boxLogger");
  // const [type, setType] = useState("password")
  const [option, setOption] = useState(0)

  let checkUsers = async ()  => {
    await fetch("https://sheetlabs.com/ADST/QUiz_Admins" , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',

    },
  })
  .then( response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Something went wrong');
    }
  })
  .then(data => {
    
    for (let i = 0; i < data.length; i++) {
      
      let name = data[i].name;
      let password = data[i].password;
      
      if(name === userName.toLocaleLowerCase() && password === userPassword){
        validate = true;
        
      }
        
    }
    if(validate){
      setTextError("");
      // window.location.href ='/quiz';
      setInvisibleQuiz('boxQuiz')
      setInvisibleLogger('boxLoggerInv')
    }else{
      setTextError("Nombre o Contraseña incorrecta");
      setInvisibleQuiz('boxQuizInv')
      setInvisibleLogger('boxLogger')
    }
    
  })}

  

  const  handleUser = event =>{
     setUserName(event.target.value);
     
     
    
  }

  const  handlePassword = event =>{
    setUserPassword(event.target.value);
  }


  const hadleOption = (num) =>{
    setOption(num);
  }

  return (
    <div>
      <header>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Hachi+Maru+Pop&family=Poppins:ital,wght@0,200;0,400;0,700;1,800&display=swap" rel="stylesheet"></link>
      </header>
      <body>
        <div className={invisibleQuiz}>
          <img  src="https://www.adstore.es/wp-content/uploads/2021/09/logo-ad-store.png" alt='logo'/>
          <nav className='box box_nav'>
            <img className='img_alien' src={alien} alt='alien'/>
            <img className='img_ray1' src={ray1} alt='ray1'/>
            <img className='img_ray2' src={ray2} alt='ray2'/>
            <button className='button_nav' onClick={()=>{hadleOption(0)}} >Todas las respuestas</button>
            <button className='button_nav' onClick={()=>{hadleOption(1)}}>Respuestas por usuario</button>
          </nav>
          <div className='box box_answers'> <GetAnswers option={option} /> </div>
        </div>
        
        <div className={invisibleLogger}>
          <img className="userImg" src={userAdminImg} alt='logo'/>
          <p className= "textError" >{textError}</p>

          <input className="textField textField2" id= "nombre" type="text"  onkeypress="pulsar()" placeholder="Nombre de Usuario" onChange={handleUser} />
          <input className="textField textField2" type="password" placeholder="Contraseña" onChange={handlePassword} />
    

          <button className= "button2" type="submit" onClick={()=>{checkUsers()}} >Iniciar sesion</button>  
          
        </div>
      </body>
    </div>
  )
  
}



export default Statistics;