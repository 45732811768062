import React from 'react'
import { useState } from 'react';
import '../styles/Quiz.css';

export  const Preguntas = (props) => {
    let userName = props.userName
    const [textWarning, setTextWarning] = useState("Todo lo que diga en este cuestionario puede ser usado en su contra. Eres libre de no contestar, de inventar, de engañar... Cuanto más comprometida sea tu respuesta, más divertida será la jornada artística y los juegos y más bonitos los murales que haremos juntos. Así es.");
    const [textWarningTitle, setTextWarningTitle] = useState("WARNING");
    const [textAlert, setTextAlert] = useState("");
    const [consent, setConsent] = useState(false);
    const [buttonText, setButtonText] = useState("Acepto");
    const [size, setSize] = useState("");
    let sizeRadio = 0;
    let result = "";
    let answersText = "";
    let haddleValidateForm = 0;
    let exist = false;
    let id = 0;
    
    const validateConsent = async ()  => {
        
        if(!consent){
            setConsent(true);
            setButtonText("Enviar");
            setTextWarning("");
            setTextWarningTitle("");
        }else{
            
            haddleValidateForm=0;
            result="";
            for(let i = 0 ; i < size; i++){
                if(document.getElementById("question"+i).type === "text" ){
                    result=result + document.getElementById("question"+i).value + ";";
                    
                    if(document.getElementById("question"+i).value !== ""){
                        haddleValidateForm++;
                    }
                }
                else{
                    
                    for(let l = 0 ; l < sizeRadio; l++){
                        
                        if(document.getElementById(l+"question"+i).checked){
                            result=result + document.getElementById(l+"question"+i).value + ";";
                            haddleValidateForm++;
                        }           
                    }
                }
            }

            if(haddleValidateForm !== size){
                setTextAlert("Debes rellenar todos los campos para terminar la encuesta")
                
            }else{
                result = result.substring(0, result.length - 1); 
                
                setTextAlert("")
                putQuiz();
                
                
                
                
                
            }
            
        }
    }
    
    if(consent){

       
        fetch("https://sheetlabs.com/ADST/Quiz_Questions" , {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            
            },
        })
            .then(response => {
                if (response.status === 200) {
                return response.json();
                } else {
                throw new Error('Something went wrong');
                }
            })
            
            .then(data => {
                let bodyText = ''
                setSize(data.length)
                for (let i = 0; i < data.length; i++) {
                    var question = data[i].question;
                    var answers = data[i].answers;
                    
                    answersText= answersText + question + ";";

                    bodyText += `<p>${i+1}. ${question}</p>`
    
                    if(answers === '?'){
                        bodyText += `
                            <label>
                                <input class="textField" id="question${i}" type="text" name="${question}_name" " value="">
                                    
                            </label> `
                            
                        
                    }else{
                        var answer = answers.split(';')
                        
                        if(sizeRadio < answer.length){
                            sizeRadio = answer.length
                        }

                        for (let l = 0; l < answer.length; l++){
                            
                            bodyText += `
                            <label class="labelRadio" id="question${i}" >
                                <input class="radioButton" type="radio"  id="${l}question${i}" name="${question}_name" value="${answer[l]}">
                                <span> ${answer[l]} </span>    
                            </label>   `
                            
                        }
                    }
                    
                
                    bodyText += `<br></br><br></br>`
                }
                answersText = answersText.substring(0, answersText.length - 1); 

                document.getElementById('data').innerHTML = bodyText;
                
            })
    }

    const putQuiz = async ()=> {
        
        
        await fetch("https://sheetlabs.com/ADST/Quiz_Scores" , {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            
        },
        })
        .then(response => {
        if (response.status === 200) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
        })
        .then(data => {
            
            for(let l = 0 ; l < data.length ; l++){
                
                
                if(data[l].user.split(';')[0] === userName){
                    exist=true; 
                    id = data[l].__id;
                }else{
                    
                }
                

                
            }
        })
        .catch((error) => {
            console.error(error);
        });        
        
        if(!exist){
            let data = [ {
            "user" : userName,
            "question" : answersText,
            "answers" : result
            } ]
            await fetch("https://sheetlabs.com/ADST/Quiz_Scores" , {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',

                },
                body: JSON.stringify(data),
            })
            .then(response => {
                if (response.status === 204) {
                console.log('Success!');
                window.open('/complete', "_self");
                } else {
                throw new Error('Something went wrong on api server!');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }else{
            
            postQuiz();
        }
    }

    const postQuiz=()=>{
        let data = [ {
            "__id" : id,
            "user" : userName,
            "question" : answersText,
            "answers" : result
          } ]
           fetch("https://sheetlabs.com/ADST/Quiz_Scores" , {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              
          },
            body: JSON.stringify(data),
          })
          .then(response => {
            if (response.status === 204) {
              console.log('Success post!');
              window.open('/complete', "_self");
            } else {
              throw new Error('Something went wrong on api server!');
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }

    return ( 
        <div className = "form">
            <h2 className='textWarning'>{textWarningTitle}</h2>
            <h3 className=''>{textWarning}</h3>
            <div id = 'data' >
            </div>
            <br>
            </br>
            <p className="textAlert">{textAlert}</p>
            <div className= "buttons">
                <button className= "buttonSend" type="submit" onClick={()=>{validateConsent()}}>{buttonText}</button>
            </div>
        </div>

    )
}

    