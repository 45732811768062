import '../styles/Quiz.css';
import React from 'react';



function Complete() {  
  return (
    <div>
      <header>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Hachi+Maru+Pop&family=Poppins:ital,wght@0,200;1,800&display=swap" rel="stylesheet"/>
      </header>
      <body>
        <div className="box ">
          <h1 className='text_complete'>CUESTIONARIO ENVIADO</h1>
          <a href='https://www.adstore.es/'><button>Ir a Adstore</button></a>
        </div>
      </body>
    </div>
  )
  
}



export default Complete;